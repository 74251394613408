export const RoadmapList3 = [
    {
        text: 'Perpetually Appreciating Bluechip Offerings why buy and hold or risk IL or staking in random protocols for yield when you can earn more tokens without purchasing more for holding one token fully backed!!'
    },
    {
        text: 'Concept Dex development'
    },
    {
        text: 'Partnerships to add liquidity pairs in partner tokens paired with $UP'
    },
    {
        text: 'Permission less Launchpad for new projects and tokens'
    },
    {
        text: 'Multichain feature development and rollout'
    },
];