import React from 'react';
import frozen from '../assets/frozen.png';
import grape from '../assets/grape.png';
import magik from '../assets/magik.png';
import nova from '../assets/nova.png';
import war from '../assets/war.png';

function Partners () {
    return (
        <div className='flex flex-col !h-full w-full items-center 
        text-white  xl:items-start px-4 xl:px-20 2xl:pdx-44 lg:px-16'>

            <h3 className='font-[Orbitron] text-center 2xl:text-left text-5xl  xl:pr-0 2xl:pr-52 pt-7 lg:pt-0 leading-normal xl:leading-[110px]'>
                OUR PARTNERS
            </h3>

            <div className="images flex flex-col lg:flex-row flex-wrap 2xl:flex-nowrap justify-center mt-10 items-center self-center">
                <img src={magik} alt="" className='w-auto lg:w-56 h-fit mb-12 mx-8' />
                <img src={grape} alt="" className='w-auto lg:w-56 h-fit mb-12 mx-8' />
                <img src={frozen} alt="" className='w-auto lg:w-56 h-fit mb-12 mx-8' />
                <img src={nova} alt="" className='w-auto lg:w-56 h-fit mb-12 mx-8' />
                <img src={war} alt="" className='w-auto lg:w-56 h-fit mb-12 mx-8' />
            </div>

        </div>
    );
};

export default Partners;