export const RoadmapList = [
    {
        text: 'Discord and Twitter Creation'
    },
    {
        text: 'Community Building'
    },
    {
        text: 'Up Protocol (UP) NFT Whitelist and Mint'
    },
    {
        text: 'Ownershp NFT (owNFT) Mint'
    },
    {
        text: 'UP token presale for NFT Holders'
    },
    {
        text: 'UP website and dApp launch Mint and Redemption go live'
    },
    {
        text: 'UP Protocol Launch'
    },
    {
        text: 'Trading Bot Partnership with WarFi'
    }
];