export const RoadmapList2 = [
    {
        text: 'Create LP for free market trading and arbitrage opportunities to drive volume transactions'
    },
    {
        text: 'Create Staking poolsReward token single and LP stakers with more $UP purchased through Warbot revenue'
    },
    {
        text: 'NFT selling enabled and listing on marketplaces'
    },
    {
        text: 'Research and engage community on other investment strategies for treasury funds revenue from investments treasury will be used to buy $UP'
    }
];