import React from 'react';
import { RoadmapList4 } from './RoadmapList4';
import Roadmapinput1 from './Roadmapinput1';

function Map3 () {


    return (
        <div className='flex flex-col lg:flex-row mt-10 w-12/12 h-auto bg-[#1C1C1C] rounded pb-6'>


            <div className='w-auto lg:w-12/12'>
                {RoadmapList4.map( ( input, key ) => {
                    return <Roadmapinput1
                        key={key}
                        text={input.text}
                    />;
                }

                )}
            </div>





        </div>
    );
}

export default Map3;