
import './App.css';
import Hero from './components/Hero';
import Section2 from './components/Section2';
import Roadmap from './components/Roadmap';
import Partners from './components/Partners';
import Footer from './components/Footer';
import Header from './components/Header';


function App () {
  return (
    <>
      <div className="main  bg-black !h-full ">
        <Header />
        <div className="first bg-red-900 backgroundd">
          <Hero />
          <Section2 />
        </div>
        <Roadmap />
        <Partners />
      </div>
      <Footer />
    </>
  );
};

export default App;
