import React from 'react';
import { BsTelegram } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { BsDiscord } from "react-icons/bs";

function Footer () {
    return (
        <div className='flex flex-col h-72 w-full items-center justify-center 
        text-[#D8A425]   bg-[#1C1C1C]'>


            <div className="flex">
                <a href="https://discord.gg/V4GkGfZZUf" rel="noopener noreferrer" target="_blank">
                    <BsDiscord size={40} className='mx-2' />
                </a>
                <a href="https://twitter.com/_UpFinance" rel="noopener noreferrer" target="_blank">
                    <BsTwitter size={40} className='mx-2' />
                </a>
                <a href="https://t.me/UPFinance0" rel="noopener noreferrer" target="_blank">
                    <BsTelegram size={40} className='mx-2' />
                </a>
            </div>

            <p className='text-[#BCBCBC] mt-10 smtxt text-md lg:text-lg 2xl:text-lg font-thin'>
                Copyright @UPfinance 2022
            </p>
        </div>
    );
};

export default Footer;