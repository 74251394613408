import React from 'react';

function Roadmaptop1 () {
    return (
        <div className='flex'>
            <div className="fullcircle flex bg-rfed-100 w-80 justify-start items-center relative mt-4">
                <div className='circle border-[1px] border-white w-14 h-14 rounded-full relative flex justify-center items-center'>
                    <p className='relative q-inset-y-7 qinset-x-3
                    font-[Orbitron]  text-[#D8A425] text-2xl font-normal
                    '>1</p>
                </div>
                <div className='flex flex-col relative '>
                    <p className='absolute -inset-y-7 inset-x-3
                    font-[Orbitron] w-64 text-[#D8A425] text-lg font-normal
                    '>Q4 2022 - Q1 2023</p>
                    <div className=" line border-t-[0.5px] h-0 w-60 lg:w-[800px] xl:w-[1000px] 2xl:w-[1300px]  absolute"></div>
                </div>
            </div>
        </div>
    );
}

export default Roadmaptop1;