import React from 'react';
import './Section2.css';
import { Button } from "@material-tailwind/react";

function Section2 () {
    return (
        <div className='section2 flex flex-col !h-full w-full items-center min-h-sscreen
            xl:flex-row pt-0 lg:pt-48  xl:pt-0 px-4 xl:px-20 2xl:pdx-44 lg:px-16 !bg-black/50 
        '>
            <div className='leftside h-auto pb-20 w-full flex flex-col items-center xl:items-start pt-10 text-center xl:text-left'>


                <h2 className='text-[#D8A425] mx-6 lg:m-0 pt-10 lg:pt-14  2xl:pr-[328px] smtxt text-xl  
                font-bold'>
                    Welcome to the first UNIFIED DEFI Ecosystem
                </h2>
                <h3 className='font-[Orbitron] text-5xl lg:text-[80px] xl:text-[8d0px] 2xl:text-[36px] xl:pr-0 2xl:pr-52 pt-7 lg:pt-0 leading-normal xl:leading-[110px]'>
                    WHAT IS UP?
                </h3>

                <p className='text-[#BCBCBC] mx-6 lg:m-0 pt-10 lg:pt-1  2xl:pr-[180px] smtxt text-md lg:text-lg 2xl:text-lg font-thin'>
                    UP is a protocol developed by the UP team, aptly named dually for the name of our organization and to the direction of the tokens price action being only and always UP - created for all markets bear and bull alike.
                </p>

                <a href="https://upfinance.gitbook.io/docs/" rel="noopener noreferrer" target="_blank" >

                    <Button sizse='lg' className='mt-10 bg-white/20
                smtxt  font-thin normal-case text-md lg:text-lg 2xl:text-lg
                lg:py-4 lg:px-10 lg:mt-10
                 text-white  hover:shadow-none shadow-none 
                '>
                        Documentation</Button>
                </a>

            </div>

        </div>
    );
}

export default Section2;;