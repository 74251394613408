import React, { useState } from 'react';
import { BiMenuAltRight } from 'react-icons/bi';
import { AiOutlineClose } from 'react-icons/ai';
import logo from '../assets/logo.png';

import { BsTelegram } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { BsDiscord } from "react-icons/bs";
import { motion } from 'framer-motion';
export const List = () => {
    const links = [
        { name: 'Home', link: '/#' },
        { name: 'Docs', link: '/https://upfinance.gitbook.io/docs/' },
    ];

    return (
        <ul className='flex'>
            {links.map( ( item ) => (
                <li className='text-white dark:text-black px-14 font-semibold' key={item}>
                    <a href={item.link} rel="noopener noreferrer" target="_blank">{item.name}</a>
                </li>
            ) )}
        </ul>
    );
};



function NavBar () {

    const Lister = () => {

        const links = [
            { name: 'Home', link: '/#' },
            { name: 'Docs', link: '/https://upfinance.gitbook.io/docs/' },
        ];

        return (
            <ul

                className='relative mt-6'>
                {links.map( ( item ) => (
                    <motion.li
                        initdial={{ opacity: 0, x: -100 }}
                        whildeInView={{ opacity: 1, x: 0 }}
                        className='text-blafck smtxt font-bold dark: text-white text-5xl mb-10  akira active:text-3xl ' key={item} onClick={toggleNav}>
                        <a href={item.link} rel="noopener noreferrer" target="_blank">{item.name}</a>

                    </motion.li>
                ) )}

            </ul>
        );
    };






    const [ open, setOpen ] = useState( false );
    const toggleNav = () => {
        setOpen( !open );
    };

    const navClass = open ? 'flex' : 'hidden';


    return ( <>

        <div className={`bg-black dark:bg-black relative z-40 h-full w-full tdop-20  ${ navClass }`}>
            <div className="fixed bg-black h-screen flex w-full  p-5">

                <div className="left w-1/2 flex flex-col justify-start">
                    {/* <ul>
                        <li className='text-5xl mb-5 akira' onClick={toggleNav}>

                            <a href="#FAQ">Home</a>
                        </li>
                        <li className='text-5xl mb-5 akira' onClick={toggleNav}>

                            <a href="#team">Home</a>
                        </li>

                    </ul> */}


                    <Lister />

                    <div className="flex">

                        <div className="flex text-white mr-10 lg:mr-32 ">
                            <a href="https://discord.gg/V4GkGfZZUf" rel="noopener noreferrer" target="_blank">
                                <BsDiscord size={40} className='mx-2' />
                            </a>
                            <a href="https://twitter.com/_UpFinance" rel="noopener noreferrer" target="_blank">
                                <BsTwitter size={40} className='mx-2' />
                            </a>
                            <a href="https://t.me/UPFinance0" rel="noopener noreferrer" target="_blank">
                                <BsTelegram size={40} className='mx-2' />
                            </a>

                        </div>
                    </div>

                </div>

                <motion.div
                    initial={{ opacity: 0, x: 100 }}
                    whileInView={{ opacity: 1, x: 0 }}

                    className="right w-1/2 mt-5 flex justify-end"
                >
                    <AiOutlineClose className='text-bla ck dark: text-white text-5xl mr-2 active:text-3xl transition-all ' onClick={toggleNav} />
                </motion.div>
            </div>
        </div>
        <div className="absolute w-full z-30">

            <div className={`mx-auto mt-5 relative flex lg:hidden justify-center align-middle items-center backdrop-filter backdrop-blur-3xl  bg-white/40 h-20 w-[90%] rounded-xl `}>



                <div className="left w-1/2">
                    <img src={logo} alt="" className='ml-2 w-20 roudnded-full' />
                </div>

                <div className="right w-1/2 flex justify-end">
                    <BiMenuAltRight onClick={toggleNav}
                        className='text-black dark:text-black text-5xl mr-2 active:text-3xl transition-all ' />
                </div>


            </div>
        </div>
    </>
    );
};

export default NavBar;;