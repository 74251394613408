import React from 'react';
import "./Roadmap.css";
import Top from './helpers/Roadmaptop1';
import Map from './helpers/Map1';
import Top2 from './helpers/Roadmaptop2';
import Map2 from './helpers/Map2';
import Top3 from './helpers/Roadmaptop3';
import Map3 from './helpers/Map3';

function Roadmap () {
    return (
        <div className='flex flex-col !h-full w-full items-center min-h-screen
         text-white  lg:items-start px-4 xl:px-20 2xl:pdx-44 lg:px-16'>
            <h3 className='font-[Orbitron] text-5xl  xl:pr-0 2xl:pr-52 pt-7 lg:pt-0 leading-normal xl:leading-[110px]'>
                ROADMAP
            </h3>

            <Top />
            <Map />

            <div className="othermap flex flex-col lg:flex-row">

                <div className='part1 p-4 w-auto lg:w-6/12'>
                    <Top2 />
                    <Map2 />
                </div>


                <div className='part2 p-4 w-auto lg:w-6/12'>
                    <Top3 />
                    <Map3 />
                </div>

            </div>



        </div>
    );
}

export default Roadmap;