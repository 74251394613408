import React from 'react';

const Roadmapinput2 = ( { text } ) => {
    return (
        <div className="input flex items-center ml-5 pt-3 ">
            <div className="ogacircle self-start mt-1 mr-4">

                <div className="circle h-4 w-4 border-[1px] rounded-full flex justify-center items-center absolute">
                    <div className="circle  bg-[#D9D9D9] h-[10px] w-[10px] border-[1px] border-[#797979] rounded-full absolute"></div>
                </div>

            </div>

            <div className="text pl-3 pb-2">
                <p className='text-[#BCBCBC]  smtxt text-md lg:text-lg 2xl:text-lg font-thin'>
                    {text}
                </p>
            </div>

        </div>

    );
};

export default Roadmapinput2;