import React from 'react';
import logo from '../assets/logo.png';
import { BsTelegram } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { BsDiscord } from "react-icons/bs";
import NavBar from './Navbar';

function Header () {
    return (
        <>


            <div className="navbar flex lg:hidden relative">
                <NavBar />
            </div>



            <div className="mainheader hidden lg:flex">
                <div className='flex lg:flex-row h-36 w-screen items-centern 
        text-white  lg:items-startn justify-between items-center absolute px-4 xl:px-20 2xl:pdx-44 lg:px-16 '>

                    <img src={logo} alt="" className='flex ml-5 lg:m-l0' />


                    <div className="rightside flex items-center">
                        <div className="lg:flex smtxt text-[#BCBCBC] hidden ">
                            <a href="/#" >
                                <p className='px-2'>
                                    Home
                                </p>
                            </a>
                            <a href="https://upfinance.gitbook.io/docs/" rel="noopener noreferrer" target="_blank" >
                                <p className='px-2'>
                                    Docs
                                </p>
                            </a>

                        </div>
                        <div className="flex">

                            <div className="flex text-[#D8A425] mr-10 lg:mr-32 ">
                                <a href="https://discord.gg/V4GkGfZZUf" rel="noopener noreferrer" target="_blank">
                                    <BsDiscord size={40} className='mx-2' />
                                </a>
                                <a href="https://twitter.com/_UpFinance" rel="noopener noreferrer" target="_blank">
                                    <BsTwitter size={40} className='mx-2' />
                                </a>
                                <a href="https://t.me/UPFinance0" rel="noopener noreferrer" target="_blank">
                                    <BsTelegram size={40} className='mx-2' />
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default Header;