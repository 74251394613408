import React from 'react';
import { RoadmapList } from './RoadmapList';
import { RoadmapList2 } from './RoadmapList2';
import Roadmapinput1 from './Roadmapinput1';
import Roadmapinput2 from './Roadmapinput2';

function Map1 () {


    return (
        <div className='flex flex-col lg:flex-row mt-10 w-12/12 h-auto bg-[#1C1C1C] rounded pb-6'>


            <div className='w-auto lg:w-6/12'>
                {RoadmapList.map( ( input, key ) => {
                    return <Roadmapinput1
                        key={key}
                        text={input.text}
                    />;
                }

                )}
            </div>

            <div className='w-auto lg:w-6/12'>
                {RoadmapList2.map( ( input, key ) => {
                    return <Roadmapinput2
                        key={key}
                        text={input.text}
                    />;
                }

                )}
            </div>



        </div>
    );
}

export default Map1;