import React from 'react';
import './Hero.css';
import { Button } from "@material-tailwind/react";
import heroImg from '../assets/heroimg.png';

function Hero () {
    return (
        <div className='hero flex flex-col !h-full w-full items-center min-h-screen px-4 xl:px-20 2xl:pdx-44 lg:px-16
            xl:flex-row  !bg-black/50 pt-20
        '>
            <div className='leftside h-auto pb-20 w-full flex flex-col items-center xl:items-start pt-10 text-center xl:text-left'>
                <h1 className='font-[Orbitron] text-5xl lg:text-[70px] xl:text-[8d0px] s2xl:text-[120px] xl:pr-0 2xl:pr-52d pt-20 leading-normal xl:leading-[110px]'>ONLY AND
                    ALWAYS
                    <em className='not-italic text-transparent bg-clip-text bg-gradient-to-b from-[#C68104] to-[#efc77e]'> UP</em>

                </h1>


                <p className='mx-6 lg:m-0 pt-10 lg:pt-5  2xl:pr-[180px] smtxt text-md lg:text-lg 2xl:text-lg font-thin'>
                    Providing a great opportunity to earn Real Yield with no price depreciation, only price appreciation!!
                </p>

                <div className="buttons flex flex-col lg:flex-row">
                    <a href="https://nft.upfinance.io/?ref=1" rel="noopener noreferrer" target="_blank" >


                        <Button sizse='lg' className='mt-10 bg-gradient-to-r from-[#C68104] to-[#efc77e]
smtxt  font-thin normal-case text-black text-md lg:text-lg 2xl:text-lg
lg:py-4 lg:px-10 lg:mt-10 hover:shadow-none shadow-none hover:bg-gradient-to-r hover:from-[#efc77e] hover:to-[#C68104]
lg:mr-10
transition-all duration-100 hover:animate-pulse

'>Nft Mint</Button>
                    </a>
                    <a href="http://app.upfinance.io" rel="noopener noreferrer" target="_blank" >


                        <Button sizse='lg' className='mt-10 bg-gradient-to-r from-[#C68104] to-[#efc77e]
smtxt  font-thin normal-case text-black text-md lg:text-lg 2xl:text-lg
lg:py-4 lg:px-10 lg:mt-10 hover:shadow-none shadow-none hover:bg-gradient-to-r hover:from-[#efc77e] hover:to-[#C68104]

transition-all duration-100 hover:animate-pulse

'>Launch Dapp</Button>
                    </a>
                </div>
            </div>
            <div className='rightside w-full flex justify-center h-full '>

                <img src={heroImg} alt="" className='w-80 lg:w-[553px]' />

            </div>
        </div>
    );
}

export default Hero;